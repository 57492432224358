@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');

.App {
  text-align: center;
  color:#333333;
}

.App-logo {
  height: 50px;
}


.App-header {
  background-color: #ffffff;
  height: 10vh;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 1vmin);
  padding-left: 10px;
  padding-right: 10px;
  color: #333333;
  box-shadow: 0px 3px 5px rgba(0,0,0,0.2);
  z-index: 100;
  margin-bottom: 15px;
}

.App-footer {
  background-color: #c9c9c9;
  height: 8vh;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(7px + 1vmin);
  padding-left: 10px;
  padding-right: 10px;
  color: #333333;
  /* box-shadow: 0px 3px 5px rgba(0,0,0,0.2); */
  z-index: 100;
  /* position: absolute; */
  bottom: 0px;
  width: -webkit-fill-available;
}

.App-footer a {
  color:#333333;
  text-decoration: none;
}

.App-footer span {
  display: inline;
}


.App-link {
  color: #61dafb;
}


.App section {
  display: block;
  flex-wrap: wrap;
  flex-direction: row ;
  justify-content: left;
  background-color: rgb(255, 255, 255);
  padding: 5px;
  align-content: center;
  min-width: 100% !important;
}

.App section_breadcrumb {
  background-color: rgb(255, 255, 255);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  align-content: center;
  min-width: 99% !important;
}

.section_body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row ;
  justify-content: space-evenly;;
  background-color: rgb(255, 255, 255);
  padding: 5px;
  align-content: center;
  min-width: 100% !important;
}

.maxSpace {
  min-width: 100% !important;
}

.centerContent{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  width: 100%;
}

.section_title{
  text-align: left;
  font-size: 1.5rem;
  padding: 13px;
}

.exhibitionCard{
  border-radius: 5px;
  background-color: rgb(241, 241, 241);
  margin: 1rem;
  width: 300px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border: 1px solid rgb(131, 131, 131, 0.15);
  text-decoration: none; 
}

.exhibitionCard_Info{
  overflow: hidden;
  position: relative;
}

.exhibitionCard img{
  object-fit: fill;
  display: block;
  width: 100%;
  transition: filter 0.3s ease-in-out;
  height: 400px;
}

.exhibitionCard:hover img{
  filter: grayscale() blur(2px);
}

.exhibitionCard a{
  color:rgb(131, 131, 131);
  text-decoration: none; /* no underline */
}

.overlay {
  background-color: white;
  color: black;
  position:absolute;
  bottom:0;
  left:0;
  right: 0;
  overflow: auto;
  max-height: 100%;
  padding: 1rem;
  transform: translateY(103%);
  transition: transform 0.3s ease-in-out;
  text-align: left;
  box-shadow: 0px -3px 5px rgba(0,0,0,0.1);
  border-top-left-radius:  15px;
  border-top-right-radius: 15px;
}

.overlay h3{
  margin-top: 30px;
}

.exhibitionCard:hover .overlay {
  transform: translateY(0%);
}

#iconLoading{
  margin-top: 10px;
  margin-right: 20px;
  color: #333333;
}


#blocker{
  background-color: #2c3e50bb;
  position: absolute;
  width: 84vw;
  height: 66vh; 
}


.containerViewportSize{
  width: 84vw;
  height: 66vh;
}

.OverZ{
  z-index: 10000;
}

.centerHorizontally{
  margin-left: auto;
  margin-right: auto;
}

#divLoading{
  color: white;
}


#divInstructions{
  color: white;
  font-size: 28px;
  cursor: pointer;
}

#divInstructions span{
  color: white;
  font-size: 50px;
}

#InfoPanel{
  position: relative;
  overflow: hidden;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}

#CanvasInfo{
  min-width: 250px;
  max-width: 250px;
  height: 66vh;
  position: fixed;
  /* right: 0; */
  background-color: #ffffffbb;
  color: #404040;
  transition-timing-function: ease-in-out;
  transition: transform 300ms, opacity 300ms;
  /* z-index: 10000; */
  box-shadow: 15px 0px 15px -5px rgb(0 0 0 / 37%);
  padding-left: 15px;
  padding-right: 15px;
}

#CanvasInfo a{
  color:rgb(240, 240, 240);
  text-decoration: none; /* no underline */
}

#CanvasInfo .title{
    font-size: clamp(0.5rem, -0.875rem + 8.333vw, 1.5rem);
    font-weight: 800;
    /* margin-bottom: 10px; */
    margin-top: 45px;
    /* padding-top: 10px; */
    display: block;
    text-align: center;
}

#CanvasInfo .author{
  /* color: red; */
  margin-bottom: 10px;
  display:block;
}

#CanvasInfo .desciption{
  /* color: red; */
  margin-bottom: 20px;
  text-align: justify;
  display:block;
}

#CanvasInfo .size{
  /* color: red; */
  margin-bottom: 10px;
  display:block;
  text-align: left;
}

#CanvasInfo .type{
  /* color: red; */
  margin-bottom: 10px;
  display:block;
  text-align: left;
}

#CanvasInfo .price{
  /* color: red; */
  margin-bottom: 10px;
  display:block;
  text-align: left;
}

#CanvasInfo .btnShop{
  /* color: red; */
  width: 100%;
  background-color: rgb(131,131,131);
  margin-top: 20px;
}

#CanvasInfo .btnShopSold{
  width: 100%;
  background-color: rgb(255, 118, 118);
  margin-top: 20px;
  margin-bottom: 40px;
}

.HidePanel {
  /* transform: translateX(-255px); */
  opacity: 0.0;
  display: none;
}


.ShowPanel {
  /* transform: translateX(0px); */
  opacity: 1.0;
  display: block;
}

.nofoundContainer {
  margin-top:70px;
  margin-bottom:70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family:"Nunito Sans";
  color: var(--blue);
  font-size: 1em;
}

.nofoundContainer button {
  font-family:"Nunito Sans";
}

.nofoundContainer ul {
  list-style-type: none;
  padding-inline-start: 35px;
}

.nofoundContainer .container > svg {
  width: 100%;
}

.nofoundContainer h1 {
  font-size: 7.5em;
  margin: 15px 0px;
  font-weight:bold;
}

.nofoundContainer h2 {
  font-weight:bold;
} 

.nofoundContainer{
  flex-grow: 1;
  padding: 30px;
}

.containerErrorExhibit{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}